import { useCallback } from "react";

export const SideBar = ({ open, handleSideBar, newChat }) => {
  const closeSideBar = useCallback(() => {
    handleSideBar(false);
  }, [handleSideBar]);

  const handleNewChat = useCallback(() => {
    newChat();
    closeSideBar();
  }, [newChat, closeSideBar]);

  return (
    open && (
      <>
        <div className="w3-sidebar w3-animate-left">
          <div className="m-8">
            <button className="input relative" onClick={handleNewChat}>
              <span>New Chat</span>
              <img
                src="/n_plus-sign.svg"
                height="25px"
                width="25px"
                className="absolute right-[5px] top-[7px]"
              />
            </button>
          </div>
        </div>
        <div className="absolute z-20 right-1/4 top-5" onClick={closeSideBar}>
          <img
            src="/n_plus-sign.svg"
            height="25px"
            width="25px"
            className="rotate-45"
          />
        </div>
      </>
    )
  );
};
