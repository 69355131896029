import * as PropTypes from "prop-types";
import { Layout } from "./Layout";
import { useCallback, useState } from "react";

Layout.propTypes = { children: PropTypes.node };

export const LandingHeader = ({...props}) => {
  const [open, setSideBar] = useState(false);

  const handleSideBar = useCallback(() => {
    setSideBar(!open);
  }, [open, setSideBar]);
  return (
      <>
        <header className="flex justify-center py-6 px-2 items-center bg-noomaBlack italic">
          <div className="flex flex-col items-center">
            <div>
              <img src="/nooma_logo.svg" alt="nooma" height="24px" width="28px"/>
            </div>
            <div className="text-noomaWhite ">powered by nooma</div>
          </div>
        </header>
        <div className="w-screen border-b border-gray-400 "></div>
      </>
  );
};
