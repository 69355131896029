import { useCallback, useState } from "react";
import config from "../config";
import { Header } from "./Header";
import { Main } from "./Main";

export const AskNala = ({}) => {
  const [answer, setAnswer] = useState([]);
  const [indicator, setIndicator] = useState(false);
  const clearAnswers = useCallback(() => {
    setAnswer([]);
  }, [setAnswer]);
  const handleSubmit = useCallback(
    (value) => {
      const url = `${config.urls.apiURL}/asknala`;
      setIndicator(true);
      fetch(url, {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "TE": "trailers",
        },
        body: JSON.stringify({
          question: value,
        }),
      }).then((response) => {
        response.json().then((res) => {
          setAnswer([...answer, res.answer]);
          setIndicator(false);
        });
      });
    },
    [answer, setAnswer, setIndicator]
  );
  const handleOnEnter = useCallback(
    (e) => {
      if (e.key === "Enter") {
        handleSubmit(e.target.value);
      }
      if (e.type === "click") {
        handleSubmit(e.value);
      }
    },
    [handleSubmit]
  );
  return (
    <>
      <div className="flex flex-col justify-between grow h-screen">
        <Header newChat={clearAnswers} />
        <Main
          onKeyDown={handleOnEnter}
          answer={answer}
          onClick={handleSubmit}
        />
      </div>
      {indicator && (
        <div className="absolute h-screen w-screen top-0 left-0 flex justify-center items-center bg-transparent bg-black/50">
          <img src="/indicator.svg" />
        </div>
      )}
    </>
  );
};
