const config = {
  development: {
    urls: {
      publicURL: "http://localhost:3000",
      apiURL: "http://127.0.0.1:5000",
    },
    auth0: {
      domain: "dev-7v4eazlfb4orwp5c.us.auth0.com",
      clientId: "lEhGmqqDR4smr8vKg1IPCIldIQVM0PJ9",
      redirectUri: window.location.origin,
    },
  },
  production: {
    urls: {
      publicURL: "https://asknala.co",
      apiURL: "https://4e4unwilib.execute-api.us-west-2.amazonaws.com/prod",
    },
    auth0: {
      domain: "nooma.us.auth0.com",
      clientId: "IbsjJxVVVS2vV6ss2uI7ZXxUZs1dZtvg",
      redirectUri: window.location.origin,
    },
  },
};

// eslint-disable-next-line no-undef
export default config[process.env.NODE_ENV];
