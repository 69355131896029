import * as PropTypes from "prop-types";
import { Layout } from "./Layout";
import { HamburgerIcon } from "../icons/HamburgerIcon";
import { LightBulb } from "../icons/LightBulb";
import { useCallback, useState } from "react";
import { SideBar } from "./SideBar";

Layout.propTypes = { children: PropTypes.node };

export const Header = ({ ...props }) => {
  const [open, setSideBar] = useState(false);

  const handleSideBar = useCallback(
    (value) => {
      setSideBar(value || !open);
    },
    [open, setSideBar]
  );
  return (
    <>
      <SideBar open={open} handleSideBar={handleSideBar} {...props} />
      <Layout>
        <header className="flex justify-between py-6 px-2 items-center">
          <div onClick={handleSideBar}>
            <HamburgerIcon />
          </div>
          <div className="text-3xl"> NALA 1.0</div>
          <div>
            <LightBulb />
          </div>
        </header>
      </Layout>
      <div className="w-screen border-b border-gray-400 "></div>
    </>
  );
};
