import { Link } from "react-router-dom";

export const LandingMain = () => (
  <main>
    <div className="flex flex-col h-[89.5vh] sm:h-[85vh] md:h-[87vh] lg:h-[87vh] xl:h-[90vh]">
      <div className=" flex flex-1 h-64 grow basis-3/5 overflow-auto nooma-background justify-center items-center">
        <img src="/logo_2-1.svg" height="253px" width="253px" alt="nala" />
      </div>
      <div
        className="bg-noomaBlack text-noomaWhite flex
       justify-between flex h-32 grow basis-2/5 flex-col items-center gap-2 pb-10 text-white py-16"
      >
        <div className="text-2xl"> NALA 1.0</div>
        <div className="text-center text-white px-8 text-sm">
          Our vision is for Nala to become the search engine of choice for
          pharma regulatory data and insights
        </div>
        <Link to="/asknala">
          <img src="/arrow.png" alt="askNala" />
        </Link>
      </div>
    </div>
  </main>
);
