import { Layout } from "./Layout";
import { Informational } from "../blocks/Informational";
import { Answer } from "./Answer";
import { useCallback, useState } from "react";

export const Main = ({ onKeyDown, answer, onClick }) => {
  const [value, setValue] = useState("");
  const handleOnChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [value, setValue]
  );
  return (
    <main className="nooma-background grow">
      <div className="flex flex-col gap-4 h-[87vh] sm:h-[85vh] md:h-[87vh] lg:h-[87vh] xl:h-[87vh]">
        {answer.length === 0 ? (
          <div className="flex-1 h-64 grow basis-11/12 overflow-auto">
            <Informational />
          </div>
        ) : (
          <div className="flex-1 h-64 grow basis-11/12 overflow-auto">
            {answer.map((i, index) => (
              <Answer answer={i} key={index} />
            ))}
          </div>
        )}
        <div>
          <Layout>
            <div className="flex justify-center border border-nalaGray rounded-md bg-white">
              <textarea
                type="text"
                className="input border-0 w-full min-h-fit"
                placeholder="Ask me anything"
                onKeyDown={onKeyDown}
                value={value}
                onChange={handleOnChange}
              />
              <button onClick={() => onKeyDown({ type: "click", value })}>
                <img
                  src="/n_btn_submit_default.svg"
                  width="50px"
                  height="50px"
                />
              </button>
            </div>
          </Layout>
        </div>
      </div>
    </main>
  );
};
