import { Route, Routes } from "react-router";
import { LandingHeader } from "./components/LandingHeader";
import { LandingMain } from "./components/LandingMain";
import { AskNala } from "./components/AskNala";

const App = () => (
  <Routes>
    <Route
      path="/"
      element={
        <>
          <LandingHeader />
          <LandingMain />
        </>
      }
    />
    <Route path="/askNala" element={<AskNala />} />
  </Routes>
);

export default App;
