export const HamburgerIcon = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="2" x2="16" y2="2" stroke="black" stroke-width="4" />
    <line y1="9" x2="16" y2="9" stroke="black" stroke-width="4" />
    <line y1="16" x2="16" y2="16" stroke="black" stroke-width="4" />
  </svg>
);
